import { Box, Button, Form, FormField, Text, Layer } from "grommet";
import React from "react";
import { opayWallet } from "../services/helpers";
import LoadingView from "./loading";
export default function WalletForm({ trans, upg, merchant, onSuccess }) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const pay = async (mobile) => {
    try {
      setLoading(true);
      const init = await opayWallet(trans.uid, mobile);
      if (init) {
        // window.location = "/wallet-success/" + trans.uid;
        // setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          onSuccess();
        }, 1000);
        setTimeout(() => {
          setSuccess(false);
        }, 10000);
      }
    } catch (e) {
      window.location = "/error?e=" + e.message;
    }
  };
  return (
    <>
      {" "}
      {loading && (
        <Layer
          onEsc={() => {
            // this.setState({ isOnFrame: false });
          }}
          onClickOutside={() => {
            // this.setState({ isOnFrame: false });
          }}
          modal
          style={{ zIndex: 111 }}
        >
          <Box
            width="medium"
            height="medium"
            pad={{ vertical: "large", horizontal: "large" }}
            style={{ zIndex: 99 }}
          >
            <LoadingView text={"Loading your secure payment"} />
          </Box>
        </Layer>
      )}
      <Box pad="small">
        {loading && <LoadingView />}

        {!loading && (
          <>
            {success ? (
              // <Text size="medium" color="light-6" weight="bold">
              //   Follow instructions sent over your phone number linked to the
              //   wallet
              // </Text>
              <></>
            ) : (
              <>
                <Text
                  size="medium"
                  color="brand"
                  weight="bold"
                  margin={{ bottom: "medium", top: "small" }}
                >
                  Please Enter Your Mobile
                </Text>
                <Form
                  validate="change"
                  onReset={(event) => console.log("")}
                  onSubmit={({ value }) => pay(value.mobile)}
                  onValidate={(validationResults) => {
                    setValid(validationResults.valid);
                  }}
                >
                  <FormField
                    label="Mobile Number"
                    name="mobile"
                    placeholder="Enter your mobile number"
                    required
                    validate={[
                      {
                        regexp: /^(\+\d{1,3}[- ]?)?\d{11}$/,
                        message: "isn't a valid mobile number",
                      },
                      (mobile) => {
                        if (mobile.length !== 11)
                          return "isn't a valid mobile number";
                        return undefined;
                      },
                    ]}
                  />
                  <Box
                    direction="row"
                    justify="between"
                    margin={{ top: "medium" }}
                  >
                    <Button
                      type="submit"
                      label={<Text color="white">Pay Now</Text>}
                      disabled={!valid || loading}
                      primary
                      size="large"
                    />
                  </Box>
                </Form>
              </>
            )}
          </>
        )}
        {/* <Text size="small">Operated By Opay</Text> */}
      </Box>
    </>
  );
}
