import { Box, Button, Text } from "grommet";
import React from "react";

export default function UpgForm({ trans, upg, merchant }) {
  const [loading, setLoading] = React.useState(false);
  const initSesssion = () => {
    const info = {
      OrderId: "",
      paymentMethodFromLightBox: 2,
      MID: upg.merchant_id,
      TID: upg.terminal_id,
      SecureHash: upg.secureHash,
      TrxDateTime: upg.txDateTime,
      AmountTrxn: trans.amount * 100,
      MerchantReference: upg.reference_no,
      ReturnUrl: merchant.return_url,
      completeCallback: function (data) {
        window.location.href =
          process.env.GATSBY_GATEWAY_URL +
          "/api/" +
          trans.uid +
          "/" +
          upg.hmac +
          "/" +
          upg.txDateTime;
      },
      errorCallback: function (e) {
        if (typeof window !== "undefined") {
          window.location = "/error?e=" + e.message;
        }
      },
      cancelCallback: function (res) {
        setLoading(false);
      },
    };
    if (window.Lightbox) {
      window.Lightbox.Checkout.configure = info;
    }
    window.Lightbox.Checkout.showLightbox();
  };
  return (
    <>
      <Box
        pad="small"
        // overflow={{ horizontal: "hidden", vertical: "scroll" }}
      >
        <Text
          size="medium"
          color="brand"
          weight="bold"
          margin={{ bottom: "medium", top: "small" }}
        >
          You can pay using meza cards
        </Text>

        <Button
          margin={{ vertical: "large" }}
          primary
          disabled={loading}
          size="large"
          label={<Text color="white">Pay Now</Text>}
          onClick={() => {
            setLoading(true);
            initSesssion();
          }}
        />
      </Box>
    </>
  );
}
