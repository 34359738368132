import { Box, Heading, Image, Text } from "grommet";
import * as moment from "moment";
import React from "react";

export default function ScheduledTrans({ trans, merchant }) {
  return (
    <>
      <Box pad={{ vertical: "medium" }}>
        <Text color="light-6">{"Welcome"}</Text>
        <Text weight="bold">
          {trans.first_name} {trans.last_name}
        </Text>
      </Box>
      <Box pad={{ vertical: "medium" }} direction="row">
        <Box
          width="48px"
          height="48px"
          background={{
            color: "#00BE00",
            opacity: 0.2,
          }}
          round="50%"
          align="center"
          direction="row"
          justify="center"
          pad={{ top: "25px", left: "10px", right: "10px" }}
        >
          <Image
            src={require("../images/done.png")}
            width="48px"
            height="48px"
          />
        </Box>
        <Box pad={{ horizontal: "small" }}>
          <Text weight="bold" color="neutral-1">
            {"Generated Successfully"}
          </Text>
          {trans.scheduled_at && (
            <Text>{`your payment scheduled to ${trans.scheduled_at}.`}</Text>
          )}
          {!!(trans.fawry_ref && trans.fawry_internal_ref) && (
            <>
              <Text>
                {`Fawry Reference: #`} <b>{trans.fawry_internal_ref}</b>
              </Text>
              <Text>
                {`كود فوري المرجعي : #`} <b>{trans.fawry_internal_ref}</b>
              </Text>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
