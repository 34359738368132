import { Box, Button, Form, FormField, Text } from "grommet";
import React from "react";
import { fawryRef, opayRef } from "../services/helpers";
import Alert from "react-popup-alert";
import { useAlert } from "react-alert";

export default function FawryForm({ reference, merchant, onSuccess, trans }) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [valid, setValid] = React.useState(true);
  const [value, setValue] = React.useState({
    mobile: trans.mobile,
  });
  const alert = useAlert();

  const pay = async (mobile) => {
    try {
      // setLoading(true);
      const init = await fawryRef(trans.uid, mobile);
      if (init && init.data) {
        // window.location = "/wallet-success/" + trans.uid;
        // setLoading(false);
        if (init.data.ref) {
          let code = init.data.ref;
          alert.success(
            `Please use code ${code} in Fawry POS to pay this transaction, براجاء استخدام الكود ${code} للدفع من خلال مكينات الدفع الخاصه بفوري`,
            {
              title: "Success",
              actions: [
                {
                  copy: "Copy code, نسخ الكود",
                  onClick: () => navigator.clipboard.writeText(code),
                },
                {
                  copy: "Okey",
                  onClick: () => window.location.reload(),
                },
              ],
              timeout: 0,
            }
          );
        }
      } else {
        alert.error(`Error!, something went wrong. `);
      }
    } catch (error) {
      let msg = "Something went wrong, please try again!";
      if (error.response) {
        msg = error.response.data
          ? error.response.data.error + " " + error.response.data.message
          : "Wrong response data!";
      }
      console.log(error.toJSON());
      alert.error(`Error!, ${msg}`);
    }
  };

  return (
    <>
      <Form
        validate="change"
        defaultValue={{
          mobile: trans.mobile,
        }}
        value={value}
        onChange={(nextValue) => setValue(nextValue)}
        onReset={() => setValue({})}
        onSubmit={({ value }) => pay(value.mobile)}
      >
        <FormField
          label="Mobile Number"
          name="mobile"
          placeholder="Enter your mobile number"
          required
          validate={[
            {
              regexp: /^(\+\d{1,3}[- ]?)?\d{11}$/,
              message: "isn't a valid mobile number",
            },
            (mobile) => {
              if (mobile.length !== 11) return "isn't a valid mobile number";
              setValid(true);
              return undefined;
            },
          ]}
          defaultValue={trans.mobile}
        />
        <Box direction="row" justify="between" margin={{ top: "medium" }}>
          <Button
            type="submit"
            label={<Text color="white">Pay Now</Text>}
            disabled={!valid || loading}
            primary
            size="large"
          />
        </Box>
      </Form>
    </>
  );
}
